import { IHeader } from '@/components/DataTable/types'

import { ITeamAnalystsDetailProps, ITeamVolumeDetailProps } from '../../types'

export const mainAnalystTableHeaders: Array<IHeader> = [
  {
    name: 'Analista',
    key: 'user',
    type: 'element',
    width: '40%',
  },
  {
    name: 'Data Final de Vínculo',
    key: 'date',
    align: 'center',
    width: '20%',
  },
  {
    name: 'STATUS',
    key: 'status',
    type: 'element',
    align: 'center',
    width: '20%',
  },
  {
    name: '',
    key: 'edit',
    type: 'element',
    width: '5%',
  },
]

export const volumeTableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volume',
    type: 'element',
    width: '40%',
  },
  {
    name: 'Analista 1',
    key: 'analist1',
    align: 'center',
    width: '20%',
  },
  {
    name: 'Analista 2',
    key: 'analist2',
    align: 'center',
    width: '20%',
  },
  {
    name: 'STATUS',
    key: 'status',
    type: 'element',
    align: 'center',
    width: '20%',
  },
  {
    name: '',
    key: 'edit',
    type: 'element',
    width: '5%',
  },
]

export const analystDataToTableExample: ITeamAnalystsDetailProps[] = [
  {
    id: '1',
    name: 'Fulano de tal',
    cpf: '000.000.000-00',
    email: 'fulano.detal@email.com',
    date: '00/00/0000',
    status: {
      tag: 'active',
    },
  },
  {
    id: '2',
    name: 'Fulano de tal',
    cpf: '000.000.000-00',
    email: 'fulano.detal@email.com',
    date: '00/00/0000',
    status: {
      tag: 'active',
    },
  },
  {
    id: '3',
    name: 'Fulano de tal',
    cpf: '000.000.000-00',
    email: 'fulano.detal@email.com',
    date: '00/00/0000',
    status: {
      tag: 'active',
    },
  },
  {
    id: '4',
    name: 'Fulano de tal',
    cpf: '000.000.000-00',
    email: 'fulano.detal@email.com',
    date: '00/00/0000',
    status: {
      tag: 'active',
    },
  },
  {
    id: '5',
    name: 'Fulano de tal',
    cpf: '000.000.000-00',
    email: 'fulano.detal@email.com',
    date: '00/00/0000',
    status: {
      tag: 'inactive',
    },
  },
  {
    id: '6',
    name: 'Fulano de tal',
    cpf: '000.000.000-00',
    email: 'fulano.detal@email.com',
    date: '00/00/0000',
    status: {
      tag: 'active',
    },
  },
]

export const volumeToTableExample: ITeamVolumeDetailProps[] = [
  {
    id: '1',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'not_started',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '2',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'not_started',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '3',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'not_started',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '4',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'not_started',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '5',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'not_started',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '6',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'in_progress',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '7',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'in_progress',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '8',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'in_progress',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
  {
    id: '9',
    name: 'AL LE 000 000 0000 PP00 00 00 000 000',
    editora: 'Editora 1',
    colecao: 'Coleção 1',
    analyst: [
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
      {
        label: 'Fulano de tal',
        value: '000.000.000-00',
      },
    ],
    status: {
      tag: 'finished',
    },
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },
]

import React, { useMemo } from 'react'
import { useState } from 'react'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

import { Button, Text, Flex } from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'

import { IDeleteModalProps } from './types'

const DeleteTeamModal = ({
  isOpen,
  onClose,
  teams,
}: IDeleteModalProps): JSX.Element => {
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTeams, setSelectedTeams] = useState<Array<RowData>>([])

  const pageInfo = useMemo(() => {
    const itemsPerPage = 3

    return {
      itemsPerPage,
      pageCount: Math.ceil(teams.length / itemsPerPage),
      itemsCount: teams.length,
    }
  }, [teams])

  const teamsPaginated = useMemo(() => {
    const start = page * pageInfo.itemsPerPage
    const end = start + pageInfo.itemsPerPage

    return teams.slice(start, end)
  }, [page, teams])

  const handleDelete = async () => {
    setIsLoading(true)
    // Perform the delete operation here
    // You can call the onDelete callback after the delete operation is successful
    setIsLoading(false)
    onClose()
  }

  const mainTableHeaders: Array<IHeader> = [
    {
      name: 'EQUIPE',
      key: 'user',
      type: 'element',
    },
    {
      name: 'SUPERVISOR',
      key: 'supervisor',
      type: 'element',
    },
    {
      name: 'DATA DE CRIAÇÃO',
      key: 'date',
    },
  ]

  return (
    <Modal
      title="Excluir equipes"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Text height="63px" mb="1rem">
        Confira as equipes que você selecionou antes de confirmar a exclusão.
        Deseja desativar as equipes abaixo?
      </Text>
      <Flex direction="column">
        <DataTable
          headers={mainTableHeaders}
          data={teamsPaginated}
          isLoading={isLoading}
          selectableRow
          onRowSelectionChange={rows => setSelectedTeams(rows)}
        />
        <Pagination
          itemsPerPage={pageInfo.itemsPerPage}
          pageCount={pageInfo.pageCount}
          itemsCount={pageInfo.itemsCount}
          onChangePageInfo={({ pageIndex }) => setPage(pageIndex)}
        />
      </Flex>
      <Flex gap={8} justifyContent={'flex-end'}>
        <Button
          leftIcon={<FaTimesCircle size="14px" color="white" />}
          size="sm"
          backgroundColor="brand.error.base"
          color="white"
          _hover={{ bg: 'brand.error.dark' }}
          onClick={onClose}
        >
          Não, mantenha ativo
        </Button>
        <Button
          leftIcon={<FaCheckCircle size="14px" color="white" />}
          size="sm"
          backgroundColor="brand.primary.dark_1"
          color="white"
          _hover={{ bg: 'brand.primary.dark_2' }}
          onClick={handleDelete}
        >
          Sim, quero desativar
        </Button>
      </Flex>
    </Modal>
  )
}

export default DeleteTeamModal

import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusVolumeTeamProps, IStatusAnalystTeamTagStyles } from './types'

const styles: IStatusAnalystTeamTagStyles = {
  finished: {
    name: 'Finalizado',
    backgroundColor: '#E3F5E1',
    color: '#168821',
  },
  not_started: {
    name: 'Não Iniciado',
    backgroundColor: '#F8F8F8',
    color: '#333333',
  },
  in_progress: {
    name: 'Em Andamento',
    backgroundColor: '#EDF5FF',
    color: 'brand.primary.dark_1',
  },
}

function StatusTag({ tag, ...rest }: IStatusVolumeTeamProps) {
  const { name, backgroundColor, color } = styles[tag] || styles.not_started

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag

import React from 'react'

import { ITabItem } from '@/components/Tabs/types'

import ActiveFailChecklist from './Active'
import InactiveFailChecklist from './Inactive'

export const tabs: ITabItem[] = [
  {
    id: 'ativos',
    label: 'Ativos',
    render: () => <ActiveFailChecklist />,
  },
  {
    id: 'inativos',
    label: 'Inativos',
    render: () => <InactiveFailChecklist />,
  },
]

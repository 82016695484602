import React, { useEffect, useState } from 'react'

import {
  Box,
  SimpleGrid,
  Stack,
  useColorMode,
  useToast,
} from '@chakra-ui/react'

import {
  useQueryGetCountries,
  useQueryGetStates,
  useQueryGetCities,
} from '@/api/address/queries'
import { useQueryGetSpecialNeeds } from '@/api/user/auxiliaries/queries'
import { useQueryUser } from '@/api/user/management/queries'
import { Input, Select } from '@/components/Forms'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

import { IUserDetailsProps } from '../types'
import S from './styles'

const UserInfoForm: React.FC<IUserDetailsProps> = ({ userId }) => {
  const {
    isLoadingUserData,
    specialNeedsOptions,
    educationLevelsOptions,
    countries,
    states,
    cities,
  } = useProfileContext()

  return (
    <Box width="100%">
      <Stack gap={8}>
        <SimpleGrid columns={2} spacing={4}>
          <Input
            name="noUserName"
            label="Nome"
            placeholder="Nome"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="noUserSocialName"
            label="Nome Social"
            placeholder="Digite seu nome social"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="dsCpf"
            label="CPF"
            placeholder="00.000.000-00"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="dtBirthDate"
            label="Data de nascimento"
            type="date"
            inputProps={{ max: '2999-12-31' }}
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Select
            name="coSpecialNeed"
            label="Deficiência"
            placeholder="Selecione uma opção"
            options={specialNeedsOptions || []}
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Select
            name="coEducationLevel"
            label="Nível de Escolaridade"
            placeholder="Selecione uma opção"
            options={educationLevelsOptions || []}
            isDisabled
            isLoading={isLoadingUserData}
          />
        </SimpleGrid>
      </Stack>
      <Stack gap={4}>
        <S.HeaderText>Contatos</S.HeaderText>
        <SimpleGrid columns={2} spacing={4}>
          <Input
            name="dsInstitutionalEmail"
            label="E-mail institucional"
            placeholder="nome@nees.ufal.br"
            helperText="Escreva aqui o e-mail que deseja receber notificações do SARE."
            type="email"
            isLoading={isLoadingUserData}
          />
          <Input
            name="dsGovbrEmail"
            label="E-mail do gov.br"
            placeholder="nome@email.com"
            type="text"
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Input
            name="dsCellphone"
            label="Telefone"
            placeholder="+55 (82) 99999-9999"
            type="tel"
            isDisabled
            isLoading={isLoadingUserData}
          />
        </SimpleGrid>
      </Stack>
      <Stack gap={4}>
        <S.HeaderText>Endereço</S.HeaderText>
        <SimpleGrid columns={3} spacing={4}>
          <Select
            name="dsCountry"
            label="País"
            placeholder="Selecione um país"
            options={countries}
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Select
            name="dsUfProvince"
            label="Estado"
            placeholder="Selecione um estado"
            options={states}
            isDisabled
            isLoading={isLoadingUserData}
          />
          <Select
            name="dsCity"
            label="Cidade"
            placeholder="Selecione uma cidade"
            options={cities}
            isDisabled
            isLoading={isLoadingUserData}
          />
        </SimpleGrid>
      </Stack>
    </Box>
  )
}

export default UserInfoForm

import React from 'react'

import Data from '../DataTable/Data'

const ActiveFailChecklist: React.FC = () => {
  return (
    <div>
      {/* TODO: Chamada para o componente Data reformulado para fazer a call certa da API */}
      <Data />
    </div>
  )
}

export default ActiveFailChecklist

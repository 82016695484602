import React from 'react'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'

import { Formik, Form, FormikValues } from 'formik'

import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'

import FileUpload from '@/components/FileUpload'
import Modal from '@/components/Modal'

import { IModalProps } from './types'

export const ModalProfile: React.FC<IModalProps> = ({ isOpen, onClose }) => {
  const removePicture = () => {
    // TODO: Implement remove picture
  }

  const savePicture = () => {
    // TODO: Implement save picture
  }

  const handleSubmit = async (values: FormikValues) => {
    onClose()
  }

  return (
    <Modal
      title="Editar foto de perfil"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Formik
        initialValues={{
          file: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box padding={2}>
            <Text fontSize="sm" color="gray.600" marginBottom={2}>
              Foto do perfil{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </Text>
            <FileUpload
              id="file"
              name="file"
              helperText="Só aceitamos imagens (.png ou .jpg)"
              acceptedFileTypes={['image/*']}
              maxFileSize={10}
            />
          </Box>
          <Flex
            padding={2}
            justify="flex-end"
            gap={2}
            flexDirection={{
              base: 'column',
              sm: 'row',
            }}
          >
            <Button
              type="button"
              variant="ghost"
              size="sm"
              leftIcon={<Icon as={FaChevronLeft} />}
              onClick={onClose}
            >
              Voltar
            </Button>
            <Button
              key="go-back"
              size="sm"
              leftIcon={<BsXCircle />}
              variant="error"
              onClick={onClose}
            >
              Remover foto
            </Button>
            <Button
              key="confirm"
              type="submit"
              size="sm"
              leftIcon={<BsCheckCircle />}
            >
              Salvar foto
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

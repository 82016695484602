import React from 'react'
import { useState } from 'react'
import { FaFile } from 'react-icons/fa6'

import { Button, Flex, Stack, Text } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'

import { tabs } from './controller'

const FailList = () => {
  const [activeTabId, setActiveTabId] = useState('ativos')
  return (
    <Stack p={4} boxShadow="md" borderRadius="md">
      <Flex justify="space-between" align="center">
        <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
          Lista de falhas
        </Text>
        <Button leftIcon={<FaFile />} borderRadius="md" size="sm">
          Cadastrar grupo de falhas
        </Button>
      </Flex>
      <Tabs
        items={tabs}
        initialTab={activeTabId}
        onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
        variant="rounded"
      />
    </Stack>
  )
}

export default FailList

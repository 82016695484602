import React, { useMemo, useRef, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { EditIcon } from '@chakra-ui/icons'
import { Box, IconButton, VStack, Text, Button, Stack } from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import StatusTag from '@/components/Tag/StatusAnalystTeam'

import { analystsFilterExample } from '../../exampleData'
import {
  analystDataToTableExample,
  mainAnalystTableHeaders,
} from './exampleData'

const TeamAnalystTable = () => {
  const { teamId } = useParams()
  const [pageIndex, setPageIndex] = useState(0)
  const navigate = useNavigate()

  const pageSizeOptions = [10, 20, 30, 40, 50]
  const itemsPerPage = 5
  const totalTeams = analystDataToTableExample.length
  const totalPages = Math.ceil(totalTeams / itemsPerPage)

  const handleOnChangePageInfo = ({ pageIndex }: IPageInfoProps) => {
    setPageIndex(pageIndex)
  }

  const analystsDataTable = useMemo(() => {
    const analysts = analystDataToTableExample ? analystDataToTableExample : []

    const mappedAnalysts = analysts.map(analyst => {
      const analystInfo = (name: string, cpf: string, email: string) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {name}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {cpf} | {email}
            </Text>
          </Box>
        </VStack>
      )

      const editButton = (id: string) => {
        const handleEdit = () => {
          // navigate(`/configuracoes/equipes/detalhes/${id}`)
        }

        return (
          <IconButton
            aria-label="Editar"
            variant="ghost"
            size="sm"
            height="unset"
            icon={<EditIcon />}
            color="brand.primary.dark_1"
            onClick={() => handleEdit()}
          />
        )
      }

      return {
        user: analystInfo(analyst.name, analyst.cpf, analyst.email),
        date: analyst.date,
        status: StatusTag(analyst.status),
        edit: editButton(analyst.name as string),
        status_code: analyst.status,
      }
    })
    return mappedAnalysts
  }, [analystDataToTableExample])
  return (
    <Stack gap={4}>
      <DataFilter
        testId="teams-filter"
        canPrint={false}
        canDownload={false}
        onlySearch={true}
        filters={analystsFilterExample}
        onChangeFilter={() => {}}
      />
      <DataTable
        rowId="cod_team_analyst"
        headers={mainAnalystTableHeaders}
        data={analystsDataTable}
        isLoading={false}
      />
      <Button
        leftIcon={<FaPlusCircle size="14px" color="white" />}
        size="sm"
        backgroundColor="brand.primary.dark_1"
        color="white"
        _hover={{ bg: 'brand.primary.dark_2' }}
        width="100%"
      >
        Adicionar Analista
      </Button>
      <Pagination
        pageIndex={pageIndex}
        optionsItemsPerPage={pageSizeOptions}
        itemsPerPage={itemsPerPage}
        pageCount={totalPages}
        itemsCount={totalTeams}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Stack>
  )
}
export default TeamAnalystTable

import React from 'react'

import { Field, ErrorMessage } from 'formik'

import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input as ChakraInput,
  Skeleton,
} from '@chakra-ui/react'

import { IInputProps } from './types'

export const Input = ({
  name,
  label,
  type,
  placeholder,
  helperText,
  inputProps,
  isLoading,
  ...rest
}: IInputProps) => {
  return (
    <FormControl {...rest}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <Skeleton isLoaded={!isLoading}>
        <Field
          as={ChakraInput}
          variant="filled"
          fontSize="sm"
          name={name}
          type={type}
          bg="brand.neutral.light_2"
          placeholder={placeholder}
          {...inputProps}
        />
      </Skeleton>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <ErrorMessage name={name}>
        {(message: string) => (
          <Text color="red.600" fontSize="sm">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  )
}

import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const solid = defineStyle({
  bg: 'brand.primary.dark_1',
  color: 'white',
  _hover: {
    bg: 'brand.primary.dark_2',
    _disabled: {
      bg: 'brand.primary.dark_1',
    },
  },
  _active: {
    bg: 'brand.primary.base',
  },
})

const ghost = defineStyle({
  bg: 'transparent',
  color: 'brand.primary.dark_1',
  _hover: {
    bg: 'brand.neutral.light_1',
  },
  _active: {
    bg: 'brand.primary.base',
  },
})

const outline = defineStyle({
  bg: 'transparent',
  color: 'brand.primary.dark_1',
  border: '2px solid',
  borderColor: 'brand.neutral.light_1',
  _hover: {
    bg: 'brand.primary.light_2',
  },
  _active: {
    bg: 'brand.primary.light_1',
  },
})

const success = defineStyle({
  bg: 'brand.success.base',
  color: 'white',
  _hover: {
    bg: 'brand.success.dark',
    _disabled: {
      bg: 'green.900',
    },
  },
  _active: {
    bg: 'green.900',
  },
})

const error = defineStyle({
  bg: 'brand.error.base',
  color: 'white',
  _hover: {
    bg: 'brand.error.dark',
    _disabled: {
      bg: 'red.900',
    },
  },
  _active: {
    bg: 'red.900',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: { solid, ghost, outline, success, error },
})

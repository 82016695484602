/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { BsFolderX, BsFolderPlus } from 'react-icons/bs'

import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import { Input, Select } from '@/components/Forms'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

import RelinkModal from '../parts/RelinkModal'
import UnlinkModal from '../parts/UnlinkModal'
import { IUserDetailsProps } from '../types'
import S from './styles'

const roleJobOptions = [
  { value: 'Discente', label: 'Discente' },
  { value: 'Docente', label: 'Docente' },
  { value: 'Técnico Administrativo', label: 'Técnico Administrativo' },
  { value: 'Outros', label: 'Outros' },
]

const BondsForm: React.FC<IUserDetailsProps> = ({ userId }) => {
  const { userData, isLoadingUserData, isAdmin } = useProfileContext()
  const {
    isOpen: isRelinkModalOpen,
    onOpen: onRelinkModalOpen,
    onClose: onRelinkModalClose,
  } = useDisclosure()
  const {
    isOpen: isUnlinkModalOpen,
    onOpen: onUnlinkModalOpen,
    onClose: onUnlinkModalClose,
  } = useDisclosure()

  const toast = useToast()

  const isUnlinkDisabled = !!userData?.dtJobBindEnd || isAdmin
  const hasRenewal = !!userData?.dtRenewal
  const isActive = userData?.dsStatus === 'Ativo'

  return (
    <Box width="100%">
      <Stack gap={12}>
        <Stack gap={4}>
          <S.HeaderText>Dados da Instituição</S.HeaderText>
          <Flex gap={4}>
            <Flex flex={3}>
              <Input
                label="Instituição"
                name="dsInstitution"
                placeholder="Instituição"
                isLoading={isLoadingUserData}
              />
            </Flex>
            <Flex flex={1}>
              <Select
                options={roleJobOptions}
                label="Cargo"
                name="dsRolePositionJob"
                placeholder="Cargo"
                isLoading={isLoadingUserData}
              />
            </Flex>
          </Flex>
        </Stack>
        <Stack gap={4}>
          <S.HeaderText>Dados de Vínculo</S.HeaderText>
          <SimpleGrid columns={{ base: 2, lg: 4 }} spacing={4} alignItems="end">
            <Input
              label="Data de inicio do vínculo"
              name="dtJobBindStart"
              type="date"
              placeholder="Início do vínculo"
              inputProps={{ max: '2999-12-31' }}
              isDisabled
              isLoading={isLoadingUserData}
            />
            <Input
              label="Previsão de fim do vínculo"
              name="dtRenewal"
              type="date"
              placeholder="Fim do vínculo"
              inputProps={{ max: '2999-12-31' }}
              isDisabled
              isLoading={isLoadingUserData}
            />
            <Button
              variant="error"
              leftIcon={<BsFolderX />}
              isDisabled={!!userData?.dtJobBindEnd || isActive}
              onClick={onUnlinkModalOpen}
            >
              Desvincular
            </Button>
            <Button
              variant="success"
              leftIcon={<BsFolderPlus />}
              onClick={onRelinkModalOpen}
            >
              {hasRenewal ? 'Renovar vínculo' : 'Vincular'}
            </Button>
          </SimpleGrid>
        </Stack>
      </Stack>
      <RelinkModal
        isOpen={isRelinkModalOpen}
        onClose={onRelinkModalClose}
        users={userData ? [{ ...userData, co_user: userData.coUser }] : []}
        refetchUsers={() => {}}
      />
      <UnlinkModal
        isOpen={isUnlinkModalOpen}
        onClose={onUnlinkModalClose}
        users={userData ? [{ ...userData, co_user: userData.coUser }] : []}
      />
    </Box>
  )
}

export default BondsForm

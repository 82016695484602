import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Stack,
  Image,
  Heading,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import NotFoundSVG from '@/assets/svg/not_found.svg'

const Layout404: React.FC = () => {
  const navigate = useNavigate()
  const { colorMode } = useColorMode()

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      backgroundColor="white"
      gap="1rem"
    >
      <Image
        src={NotFoundSVG}
        alt="404 Not Found"
        width="512px"
        margin="1rem"
      />
      <Heading color="gray.600" size="lg" fontWeight="500">
        Oops! Página não encontrada
      </Heading>
      <Text
        color="gray.600"
        fontSize="lg"
        textAlign="justify"
        marginX={{ base: '1rem', md: '0' }}
        as="i"
      >
        A página que você está procurando não existe!
      </Text>
      <Button
        variant={colorMode == 'dark' ? 'outline' : 'solid'}
        border={colorMode == 'dark' ? '1px solid #FFFF00' : 'none'}
        color={useColorModeValue('white', '#FFFF00')}
        bg={useColorModeValue('brand.primary.dark_1', 'transparent')}
        _hover={
          colorMode == 'light'
            ? { bg: 'brand.primary.dark_2', color: 'white' }
            : { bg: '#FFFF0099', color: 'white' }
        }
        marginTop="1rem"
        onClick={() => navigate('/')}
      >
        Ir para a página inicial
      </Button>
    </Stack>
  )
}

export default Layout404

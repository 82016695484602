import React, { useRef, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { FaExchangeAlt } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { Formik } from 'formik'

import { Flex, Box, Button, Tag, Text, Stack, Select } from '@chakra-ui/react'

import { Input } from '@/components/Forms'
import Tabs from '@/components/Tabs'
import { ITabInfo, ITabItem } from '@/components/Tabs/types'

import TeamAnalystTable from './parts/Details/TeamAnalystTable'
import TeamVolumeTable from './parts/Details/TeamVolumeTable'

const TeamDetails = () => {
  const { teamStatus, teamID } = useParams()
  const [activeTabId, setActiveTabId] = useState('informacoes-pessoais')
  const navigate = useNavigate()

  const tabs: Array<ITabItem> = [
    {
      id: 'analistas',
      label: 'Analistas',
      render: () => <TeamAnalystTable />,
    },
    {
      id: 'volumes',
      label: 'Volumes',
      render: () => <TeamVolumeTable />,
    },
  ]

  return (
    <>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Detalhes da equipe
          </Text>
          <Tag
            bg="brand.primary.light_2"
            color="brand.primary.dark_1"
            fontWeight="medium"
          >
            Em Andamento
          </Tag>
        </Flex>
        <Formik
          initialValues={{
            name: 'Objeto 01: Obras Didáticas - Impresso e Digital-Interativo',
            code: '0172 P22 02 02 000 000',
            notice: '2023-24',
          }}
          onSubmit={() => {}}
        >
          <Flex gap={4}>
            <Input isDisabled name="notice" label="Edital" flex={1} />
            <Input isDisabled name="name" label="Objeto" flex={2} />
            <Input isDisabled name="code" label="Código do Objeto" flex={1} />
          </Flex>
        </Formik>
        <Formik
          initialValues={{
            fase: 'Em Andamento',
            supervisor: 'João da Silva',
            date: '10/10/2021',
          }}
          onSubmit={() => {}}
        >
          <Flex gap={4}>
            <Input isDisabled name="fase" label="Fase" flex={1} />
            <Input isDisabled name="supervisor" label="Supervisor" flex={2} />
            <Input isDisabled name="date" label="Data de criação" flex={1} />
          </Flex>
        </Formik>
        <Flex justifyContent="flex-end" gap={4}>
          <Button
            leftIcon={<FaExchangeAlt size="14px" color="white" />}
            size="sm"
            fontSize="sm"
            fontWeight="semibold"
            py={0}
            px={3}
            borderRadius="6px"
            backgroundColor="brand.error.base"
            color="white"
            _hover={{ bg: 'brand.error.dark' }}
            // onClick={handleRedistributeTeam}
          >
            Redistribuir
          </Button>
        </Flex>
      </Stack>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Box>
          <Tabs
            items={tabs}
            initialTab={activeTabId}
            onTabClick={({ id }: ITabInfo) => {
              setActiveTabId(id)
            }}
          />
          <Flex
            direction="row"
            justifyContent="flex-start"
            gap="2"
            marginTop="10"
          >
            <Button
              size="sm"
              leftIcon={<BsChevronLeft />}
              variant="ghost"
              fontSize="sm"
              color="brand.primary.dark_1"
              onClick={() => navigate('/configuracoes/equipe')}
            >
              Voltar
            </Button>
          </Flex>
        </Box>
      </Stack>
    </>
  )
}

export default TeamDetails
